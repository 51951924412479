import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import './i18n';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HashRouter } from 'react-router-dom';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

// For GET requests
axios.interceptors.request.use(
  (req) => {
     // Add configurations here (ex: auth token)
     return req;
  },
  (err) => {
     return Promise.reject(err);
  }
);

// For POST requests
axios.interceptors.response.use(
  (res) => {
     return res;
  },
  (err) => {
     return Promise.reject(err);
  }
);

 const LDProvider = withLDProvider({
  clientSideID: process.env.REACT_APP_LD_ID??"",
  context: {
    "kind": "user",
    "key": "anonymous",
    "name": "anonymous",
    "email": "anonymous@example.com"
  },
  options: {  }
})(App);

ReactDOM.render(
  <HashRouter>
    <LDProvider />
</HashRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
