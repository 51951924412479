import { Amplify } from 'aws-amplify';
import { resendSignUpCode, signIn, signOut,  } from 'aws-amplify/auth';
import { confirmSignIn, fetchAuthSession } from 'aws-amplify/auth';

class PasswordLessAuthService {
  constructor() {
    Amplify.configure({
      Auth: {
        Cognito : {
            userPoolId: process.env.REACT_APP_USERPOOLID!,
            userPoolClientId: process.env.REACT_APP_CLIENTID!,
        }
      }
    });
  }

  async signIn(email: string) {
    try {
      await signOut();
      Amplify.configure({
        Auth: {
          Cognito : {
              userPoolId: process.env.REACT_APP_USERPOOLID!,
              userPoolClientId: process.env.REACT_APP_CLIENTID!,
          }
        }
      });
      const user = await signIn({'username': email, 'options': {'authFlowType': 'CUSTOM_WITHOUT_SRP'}});
      return user;
    } catch (error) {
      throw error;
    }
  }

  async confirmSignIn(code: string, challenge: string) {
    try {
      const user = await confirmSignIn({challengeResponse: code, options : {'authFlowType': challenge}});
      return user;
    } catch (error) {
      console.error('Error confirming sign in:', error);
      throw error;
    }
  }

  async getTokens() {
    try {
      const session = await fetchAuthSession();
      return { idToken: session.tokens?.idToken?.toString(), accessToken: session.tokens?.accessToken.toString() };
    } catch (error) {
      console.error('Error getting tokens:', error);
      throw error;
    }
  }

  async getCurrentSession() {
    try {
      const session = await fetchAuthSession;
      return session;
    } catch (error) {
      console.error('Error getting current session:', error);
      throw error;
    }
  }

  // async forceRefreshSession(): Promise<any> {
  //   try {
  //     const session = await fetchAuthSession({forceRefresh: true});
  //     const idToken = session.tokens?.idToken?.toString();
  //     return await this.getPracAuthToken(idToken!, sessionStorage.getItem('providerId')!);
  //   } catch (error) {
  //     console.error('Error refreshing session:', error);
  //     throw error;
  //   }
  // }

//   async getPracAuthToken(idToken: string, providerId: string) {
//     try {
//       const response = await fetch(`${environment.practitionerAuthTokenApi}${providerId}`, {
//         headers: { "Authorization": idToken }
//       });
//       const data = await response.json();
//       sessionStorage.setItem('pracAuthToken', data['pracAuthToken']);
//       return data;
//     } catch (error) {
//       console.error('Error while fetching practitioner auth token', error);
//       throw error;
//     }
//   }

  async signOut() {
    try {
      await signOut({ global: true });
    } catch (error) {
      throw error;
    }
  }

  async resendSignUpCode(email: string, challenge: string) {
    try {
      await resendSignUpCode({username: email, options: { clientMetadata: { 'email': email, 'challengeName': challenge } }});
    } catch (error) {
      console.error('Error resending sign up code:', error);
      throw error;
    }
  }
}

export default PasswordLessAuthService;
