import { gql } from "@apollo/client";

export const GET_PRACTITIONERS = gql`
  query MyQuery($providerId: String!, $filterByCareTeams: [String]) {
    getPractitioners(
      providerId: $providerId
      filterByCareTeams: $filterByCareTeams
    ) {
      email
      lastName
      firstName
      diseases
      phoneNumber
      id
      lastLogin
      careTeams
      externalId
    }
  }
`;
// eslint-disable-next-line
type CreatePractitionerInput = {
  providerId: String;
  firstName: String;
  lastName: String;
  email: String;
  phoneNumber: String;
  diseases: [String];
  careTeams: [String];
  externalId: String;
};

export const CREATE_PRACTITIONER = gql`
  mutation MyMutation($input: CreatePractitionerInput) {
    createPractitioner(input: $input) {
      practitionerId
    }
  }
`;
// eslint-disable-next-line
type DeletePractitionerInput = {
	id: String;
	providerId: String;
}

export const DELETE_PRACTITIONER = gql`
  mutation MyMutation($input: DeletePractitionerInput) {
    deletePractitioner(input: $input) {
		practitionerId,
		reassignedPatients,
		reassignedPatientsCount
	}
  }
`;
