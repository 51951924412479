import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Button,
  Grid,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "@apollo/client/react";
import { gql, useLazyQuery } from "@apollo/client";
import {
  DELETE_PRACTITIONER,
  GET_PRACTITIONERS,
} from "../../../core/graphql/queries";
import AppContext, { AppContextType } from "../../../core/context/auth-context";
import { Practitioner } from "../../../core/interface/general/IGeneral";
import DHBackdrop from "../../generic/dh-backdrop";
import { useSnackbar } from "notistack";
import APP_CONST from "../../../core/constants/app-constants";

// eslint-disable-next-line
type ReassignPatientPractitionerInput = {
  providerId: String;
  patientId: String;
  oldPractitionerId: String;
  newPractitionerId: String;
};
const REASSIGN_PRACTITIONER = gql`
  mutation MyMutation($input: ReassignPatientPractitionerInput) {
    reassignPatientPractitioner(input: $input) {
      oldPractitionerId
      newPractitionerId
    }
  }
`;

const DeleteAndReassign = (props: any) => {
  let retryCount = APP_CONST.RETRY_Count;
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [mutateFunction, { loading: reassignLoading }] = useMutation(
    REASSIGN_PRACTITIONER
  );
  const [deleteMutation, { loading }] = useMutation(DELETE_PRACTITIONER, {});
  const { appContext } = useContext(AppContext) as AppContextType;
  const [practitionerList, setPractitionerList] = useState<Practitioner[]>([]);
  const [practitionerId, setPractitionerId] = React.useState("");
  const handleChange = (event: SelectChangeEvent) => {
    setPractitionerId(event.target.value as string);
  };

  const [getPractitioners] = useLazyQuery(GET_PRACTITIONERS, {
    variables: {
      providerId: appContext.selectedProviderId,
      filterByCareTeams: props.data.current.careTeams,
    },
    onCompleted: (data) => {
      if (data && data.getPractitioners) {
        console.log(data.getPractitioners);
        setPractitionerList(data.getPractitioners);
      }
    },
  });

  useEffect(() => {
    getPractitioners();
  }, [getPractitioners]);

  const deleteAndRessign = async () => {
    try {
      Promise.all(
        props.patientIds.map(async (patientId: string) => {
          mutateFunction({
            variables: {
              input: {
                providerId: appContext.selectedProviderId,
                patientId: patientId,
                oldPractitionerId: props.data.current.id,
                newPractitionerId: practitionerId,
              },
            },
          });
        })
      ).then(() => {
        deletePractitioner();
      });
    } catch (error) {
      enqueueSnackbar(t("error_message"), { variant: "error" });
    }
  };

  const deletePractitioner = () => {
    deleteMutation({
      variables: {
        input: {
          id: props.data.current.id,
          providerId: appContext.selectedProviderId,
        },
      },
    })
      .then(() => {
        enqueueSnackbar(t("delete_practitioner.delete_success"), {
          variant: "success",
        });
      })
      .catch((err) => {
        if (
          err?.graphQLErrors[0]?.errorType === "PatientsAreStillAssigned" &&
          retryCount > 0
        ) {
          retryCount--;
          setTimeout(() => {
            return deletePractitioner();
          }, 5000);
        } else {
          enqueueSnackbar(err.message, { variant: "error" });
        }
      })
      .finally(() => {
        props.toggleCallback();
      });
  };

  return (
    <React.Fragment>
      {(reassignLoading || loading) && <DHBackdrop></DHBackdrop>}
      <div>
        <Dialog open={props.open} onClose={props.toggleCallback} fullWidth>
          <DialogTitle>Reassign</DialogTitle>
          <DialogContent>
            <p>{t("delete_practitioner.info_text_1")}</p>
            <p>{t("delete_practitioner.info_text_2")}</p>

            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Practitioner
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={practitionerId}
                  label="Select Practitioner"
                  onChange={handleChange}
                >
                  {practitionerList.map((practitioner: Practitioner) => {
                    return (
                      <MenuItem value={practitioner.id}>
                        {practitioner.firstName} {practitioner.lastName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>

            <Grid container justifyContent="flex-end" sx={{ my: 2 }}>
              <Button
                disabled={practitionerId === ""}
                variant="contained"
                onClick={deleteAndRessign}
              >
                Submit
              </Button>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    </React.Fragment>
  );
};

export default DeleteAndReassign;
