import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { TextField, Box, Button, Container, Grid } from "@mui/material";
import APP_CONST from "../../core/constants/app-constants";
import PasswordLessAuthService from "../../core/service/passwordless-service";
import { useNavigate } from "react-router";
import React from "react";
import DHBackdrop from "../generic/dh-backdrop";
import dearLogo from '../../assets/images/dear_logo.png';
const PasswordlessInitiation = () => {
    let navigate = useNavigate();
    const passwordLessAuthService = new PasswordLessAuthService();
    const [loader, setLoader] = useState<boolean>(false);
    const defaultData = {
        username: ""
    };
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: defaultData,
    });

    const onSubmit = async (loginData: any) => {
        setLoader(true);
        try {
            const data = await passwordLessAuthService.signIn(loginData.username);
            navigate("/email-verify", { state: { ...data, ...{ 'userName': loginData.username } } });
        } catch (error) {
        } finally {
            setLoader(false);
        }
    };

    return (
        <React.Fragment>
            {loader && <DHBackdrop></DHBackdrop>}

            <Container>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{ minHeight: "100vh" }}
                >
                    <img src={dearLogo} alt="logo" />
                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                        <Box sx={{ m: 2 }}>
                            <Controller
                                name="username"
                                control={control}
                                rules={{
                                    required: "This is required",
                                    minLength: { value: 5, message: "test" },
                                    pattern: {
                                        value: APP_CONST.REGEX.EMAIL,
                                        message: "This is not a valid emaiil",
                                    },
                                }}
                                render={({
                                    field: { ref, ...field },
                                    fieldState: { invalid, error },
                                }) => (
                                    <TextField
                                        fullWidth
                                        autoFocus
                                        required
                                        label="User Name"
                                        {...field}
                                        inputRef={ref}
                                        error={invalid}
                                    />
                                )}
                            />
                            {errors.username && (
                                <Box sx={{ color: "error.main" }}>
                                    <p>{errors.username?.message}</p>
                                </Box>
                            )}
                        </Box>

                        <Box sx={{ textAlign: "center" }}>
                            <Button type="submit" variant="contained">
                                Submit
                            </Button>
                        </Box>
                    </form>
                </Grid>
            </Container>
        </React.Fragment>
    );
}
export default PasswordlessInitiation;