import React, { useState } from "react";
import "./App.css";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Providers from "./pages/providers";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import COLORS from "./core/constants/color-constants";
import AdminPage from "./pages/admin/admin-page";
import AuthContext from "./core/context/auth-context";
import OverviewPage from "./pages/overview";
import AuthPage from "./pages/auth/auth-page";
// import Login from "./components/auth/login";
// import SmsVerification from "./components/auth/sms-verification";
import { IAppDetails } from "./core/interface/general/IGeneral";
import { SnackbarProvider } from "notistack";
import PasswordlessInitiation from "./components/passwordless/initiation";
import EmailVerification from "./components/passwordless/email-verify";
import SmsVerifyComponent from "./components/passwordless/sms-verify";
export const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.orangepeel,
      contrastText: COLORS.white,
    },
    secondary: {
      main: "#FFF",
    },
    divider: "#BDBDBD",
    error: {
      main: COLORS.carmine,
    },
  },
  components: {},
  spacing: [5, 10, 15, 20],
});

function App() {
  const [appState, setAppState] = useState<IAppDetails>({} as IAppDetails);
  return (
    <AuthContext.Provider
      value={{ appContext: appState, setAppContext: setAppState }}
    >
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Routes>
            <Route element={<AuthPage />} path="/">
              {/* <Route index element={<Login />} />
              <Route path="sms" element={<SmsVerification />} /> */}
              <Route index element={<PasswordlessInitiation />} />
              <Route path="email-verify" element={<EmailVerification />} />
              <Route path="sms-verify" element={<SmsVerifyComponent />} />
            </Route>
            <Route element={<ProtectedRoute />}>
              <Route element={<AdminPage />} path="/admin">
                <Route element={<Providers />} path="providers" />
                <Route element={<OverviewPage />} path="overview" />
              </Route>
            </Route>
          </Routes>
        </SnackbarProvider>
      </ThemeProvider>
    </AuthContext.Provider>
  );
}

const ProtectedRoute = ({ redirectPath = "/" }) => {
  let isAuthenticated = true;
  return isAuthenticated ? <Outlet /> : <Navigate to={redirectPath} replace />;
};

export default App;
