import { Box, Button, Container, Grid, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import DHBackdrop from '../generic/dh-backdrop';
import { Controller, useForm } from 'react-hook-form';
import PasswordLessAuthService from '../../core/service/passwordless-service';
import { useSnackbar } from 'notistack';
import dearLogo from '../../assets/images/dear_logo.png';

const EmailVerification = () => {
    const {state} = useLocation();
    let navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [loader, setLoader] = useState<boolean>(false);
    const passwordLessAuthService = new PasswordLessAuthService();
    const defaultData = {
        emailCode: ""
    };
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: defaultData,
    });

    const onSubmit = async (loginData: any) => {
        setLoader(true);
        try {
            const data: any = await passwordLessAuthService.confirmSignIn(loginData.emailCode, state.nextStep.signInStep);
            if(data.nextStep.additionalInfo.challengeType === 'EMAIL') {
                enqueueSnackbar('Invalid mail code', {variant: 'error'});
            } else {
              navigate("/sms-verify", { state: {...data, ...{'userName': loginData.username}} });
            }
            
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false);
        }
    };

    return (
        <React.Fragment>
          {loader && <DHBackdrop></DHBackdrop>}
    
          <Container>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              style={{ minHeight: "100vh" }}
            >
              <img src={dearLogo} alt="logo" />
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Box sx={{ m: 2 }}>
                  <Controller
                    name="emailCode"
                    control={control}
                    rules={{
                      required: "This is required",
                      minLength: { value: 6, message: "This is not a valid code" },
                    }}
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <TextField
                        fullWidth
                        autoFocus
                        required
                        label="Email Code"
                        {...field}
                        inputRef={ref}
                        error={invalid}
                      />
                    )}
                  />
                  {errors.emailCode && (
                    <Box sx={{ color: "error.main" }}>
                      <p>{errors.emailCode?.message}</p>
                    </Box>
                  )}
                </Box>
    
                <Box sx={{ textAlign: "center" }}>
                  <Button type="submit" variant="contained">
                    Submit
                  </Button>
                </Box>
              </form>
            </Grid>
          </Container>
        </React.Fragment>
      );
}

export default EmailVerification;