import axios from "axios";

// Create instance called instance
const instance = axios.create({
  baseURL: process.env.API_URL,
  headers: { Accept: "application/json" },
});

export const getData = async (api: string) => {
  if(sessionStorage.getItem('adminToken')) {
    instance.defaults.headers.common['Authorization'] = sessionStorage.getItem('adminToken')!;
  }
  return await instance({ method: "GET", url: api });
};

export const postData = async (api: string, data: string) => {
  if(sessionStorage.getItem('adminToken')) {
    instance.defaults.headers.common['auth-token'] =  "Bearer " + sessionStorage.getItem('adminToken');
  }
  
  return await instance({ method: "POST", url: api, data: data,  });
};