import { Container } from "@mui/material";
import React from "react";
import { Outlet } from "react-router";
import DhAppbar from "../../components/generic/appbar";

const AdminPage = () => {

    
  return (
    <React.Fragment>
      <DhAppbar></DhAppbar>
      <Container maxWidth="xl">
        <Outlet />
      </Container>
    </React.Fragment>
  );
};

export default AdminPage;
