import {
  Box,
  Button,
  Grid,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import AppContext, { AppContextType } from "../../../core/context/auth-context";
import { ICareTeams, IDisease } from "../../../core/interface/general/IGeneral";
const AccountDetailsForm = (props: any) => {
  const { appContext } = useContext(AppContext) as AppContextType;

  const defaultData: any = {
    careTeams: [],
    externalId: "",
    providerId: appContext.selectedProviderId,
  };

  const {
    handleSubmit,
    getValues,
    setValue,
    setFocus,
    control,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: defaultData,
  });

  useEffect(() => {
    setFocus('providerId');
    for (const [key] of Object.entries(defaultData)) {
      setValue(key, props.data[key]);
    }
    // eslint-disable-next-line
  }, []);

  const handleBack = (data: any) => {
    props.backCallBack(data);
  };

  const onSubmit = (data: any) => {
    const diseaseIds: string[] = [];
    data.careTeams.forEach((selectedCareTeamID: string) => {
      const selectedCareTeam: ICareTeams = appContext.careTeams.filter(
        (careTeam: ICareTeams) => careTeam.id === selectedCareTeamID
      )[0];
      selectedCareTeam.diseases.forEach((disease: IDisease) => {
        diseaseIds.push(disease.id);
      });
    });
    data.diseases = diseaseIds;
    props.submitCallBack(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Grid container spacing={2}>
        <Grid item>
          <Box sx={{ m: 2 }}>
            <Controller
              name="providerId"
              control={control}
              rules={{
                required: "This is required",
              }}
              render={({
                field: { ref, ...field },
                fieldState: { invalid, error },
              }) => (
                <TextField
                  fullWidth
                  required
                  label="Provider Id"
                  {...field}
                  {...register("providerId")}
                  inputRef={ref}
                  error={invalid}
                />
              )}
            />
            <ErrorMessage className="error-message" errors={errors} name="providerId" as="p" />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box sx={{ m: 2 }}>
            <Controller
              name="careTeams"
              control={control}
              rules={{
                required: "This is required",
              }}
              render={({
                field: { ref, ...field },
                fieldState: { invalid, error },
              }) => (
                <>
                  <Select
                    labelId="label"
                    fullWidth
                    multiple
                    required
                    {...field}
                    inputRef={ref}
                    error={invalid}
                    label="Care Teams"
                  >
                    <MenuItem disabled value="">
                      <em>Care Teams</em>
                    </MenuItem>
                    {appContext.careTeams.map((name: ICareTeams) => (
                      <MenuItem key={name.id} value={name.id}>
                        {name.id}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            />
            {/* {errors.careTeams && (
              <Box sx={{ color: "error.main" }}>
                <p>{errors.careTeams?.message}</p>
              </Box>
            )} */}
          </Box>
        </Grid>

        <Grid item>
          <Box sx={{ m: 2 }}>
            <Controller
              name="externalId"
              control={control}
              rules={{
                required: "This is required",
              }}
              render={({
                field: { ref, ...field },
                fieldState: { invalid, error },
              }) => (
                <TextField
                  fullWidth
                  required
                  label="External Id"
                  {...field}
                  inputRef={ref}
                  error={invalid}
                />
              )}
            />
            <ErrorMessage className="error-message" errors={errors} name="externalId" as="p" />
          </Box>
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end">
      <Button variant="text" onClick={() => handleBack(getValues())}>Back</Button>
      <Button variant="contained" type="submit">Submit</Button>
      </Grid>
    </form>
  );
};

export default AccountDetailsForm;
