import { Container } from "@mui/material";
import React from "react";
import { Outlet } from "react-router";

const AuthPage = () => {

    
  return (
    <React.Fragment>
      <Container maxWidth="xl">
        <Outlet />
      </Container>
    </React.Fragment>
  );
};

export default AuthPage;
