import React, { useContext, useRef } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { GET_PRACTITIONERS } from "../../core/graphql/queries";
import { useLazyQuery } from "@apollo/client";
import { Button, Grid, Menu, MenuItem, IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useEffect, useState } from "react";

import moment from "moment";
import CreatePractitioner from "./create-practitioner/create-practitioner";
import EditPractitioner from "./edit-practitioner";
import AppContext, { AppContextType } from "../../core/context/auth-context";
import { useTranslation } from "react-i18next";
import APP_CONST from "../../core/constants/app-constants";
import {
  Practitioner,
  AssignedPatients,
} from "../../core/interface/general/IGeneral";
import { gql } from "@apollo/client";
import DeleteAndReassign from "./delete-practitioner/delete-reassign";
import DeletePractitioner from "./delete-practitioner/delete-practitioner";
import DHBackdrop from "../generic/dh-backdrop";
import ResetPassword from "./reset-password";

const GET_ASSIGNED_PATIENTS = gql`
  query MyQuery($providerId: String!, $practitionerId: String) {
    getAssignedPatients(
      providerId: $providerId
      practitionerId: $practitionerId
    ) {
      providerPractitionerId
      patientIds
    }
  }
`;

export default function PractitionersList() {
  const { t } = useTranslation();
  const changeLoginData = (data: any) => {
    if (data.value === null) {
      return t("overview_page.no_last_login_text");
    } else {
      const lastLogin = moment(data.value);
      const currentDate = moment();
      const difference = currentDate.diff(lastLogin, "month");
      if (difference === 0) {
        const utcDate = moment.utc(data.value).toDate();
        return moment(utcDate)
          .local()
          .format(APP_CONST.MOMENT_DATE_TIME_FORMAT);
      } else if (difference === 1) {
        return `> ${difference} ${t("overview_page.month_text")}`;
      } else {
        return `> ${difference} ${t("overview_page.months_text")}`;
      }
    }
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const selectedRowData = useRef({}) as Record<string, any>;
  const open = Boolean(anchorEl);
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const columns: GridColDef[] = [
    { field: "externalId", headerName: "EXTERNAL ID", flex: 1},
    { field: "firstName", headerName: "FIRST NAME", flex: 1 },
    { field: "lastName", headerName: "LAST NAME", flex: 1 },
    { field: "email", headerName: "EMAIL", flex: 1 },
    { field: "careTeams", headerName: "CARE TEAMS", flex: 1 },
    {
      field: "lastLogin",
      headerName: "Last Login",
      flex: 1,
      valueGetter: changeLoginData,
    },
    {
      field: "More",
      renderCell: (params) => {
        const handleMoreClick = (event: any) => {
          event.stopPropagation();
          const { lastLogin, __typename, ...selectedData } = params.row;
          selectedRowData.current = selectedData;
          setAnchorEl(event.currentTarget);
        };

        
        return (
          <>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-haspopup="true"
              onClick={handleMoreClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseMenu}
              PaperProps={{
                style: {
                  width: "20ch",
                },
              }}
            >
              <MenuItem onClick={openEditDialog}>Edit</MenuItem>
              <MenuItem onClick={getPatientList}>Delete</MenuItem>
              <MenuItem onClick={openResetDialog}>Reset Password</MenuItem>
            </Menu>
          </>
        );
      },
    },
  ];
  const [rowData, setRowData] = useState<Practitioner[]>([]);
  const [toggleEditDialog, setToggleEditDialog] = useState<boolean>(false);
  const [toggleReassignDialog, setToggleReassignDialog] = useState<boolean>();
  const [toggleDeleteDialog, setToggleDeleteDialog] = useState<boolean>();
  const [toggleResetDialog, setToggleResetDialog] = useState<boolean>();
  const [toggleDialog, setToggleDialog] = useState<boolean>(false);
  const { appContext } = useContext(AppContext) as AppContextType;
  const assignedPatients = useRef<AssignedPatients>({} as AssignedPatients);
  const [
    getPractitioners,
    { loading: practitionerLoading },
  ] = useLazyQuery(GET_PRACTITIONERS, {
    variables: {
      providerId: appContext.selectedProviderId,
    },
    onCompleted: (data) => {
      if (data && data.getPractitioners) {
        setRowData(data.getPractitioners);
      }
    },
  });

  const [getAssignedPatients, { loading }] = useLazyQuery(
    GET_ASSIGNED_PATIENTS,
    {
      onCompleted: (data) => {
        if (data && data.getAssignedPatients && data.getAssignedPatients.patientIds) {
          assignedPatients.current = data.getAssignedPatients;
          setToggleReassignDialog(true);
        } else {
          assignedPatients.current = {} as AssignedPatients;
          setToggleDeleteDialog(true);
        }
      },
    }
  );

  useEffect(() => {
    getPractitioners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openDialog = () => {
    setToggleDialog(true);
  };

  const closeDialog = () => {
    setToggleDialog(false);
  };

  const openEditDialog = () => {
    setToggleEditDialog(true);
    setAnchorEl(null);
  };

  const openResetDialog = () => {
    setToggleResetDialog(true);
  };

  const getPatientList = () => {
    handleCloseMenu();
    getAssignedPatients({
      variables: {
        providerId: appContext.selectedProviderId,
        practitionerId: selectedRowData.current.id,
      },
    });
  };

  const closeDeleteDialog = () => {
    setTimeout(() => {
      getPractitioners();
    }, 1000);
    setToggleDeleteDialog(false);
  };
  const closeDeleteReassignDialog = () => {
    setTimeout(() => {
      getPractitioners();
    }, 1000);
    setToggleReassignDialog(false);
  };

  const closeEditDialog = (data: Practitioner) => {
    setRowData((prev: Practitioner[]) => {
      const newState = prev.map((value) => {
        if (value.id === data.id) {
          return { ...value, ...data };
        }
        return value;
      });
      return newState;
    });
    setToggleEditDialog(false);
  };

  const closeResetDialog = (data: Practitioner) => {
    setToggleResetDialog(false);
  };

  return (
    <React.Fragment>
      {(practitionerLoading || loading) && <DHBackdrop></DHBackdrop>}
    <div>
      <Grid container>
        <Grid item>
          <h2>Users</h2>
        </Grid>
        <Grid item sx={{ marginLeft: "auto" }}>
          <Button variant="contained" onClick={openDialog}>
            New
          </Button>
        </Grid>
      </Grid>

      <DataGrid
        sx={{ display: "flex", flexDirection: "column-reverse" }}
        rows={rowData}
        columns={columns}
        pageSize={25}
        autoHeight
        disableColumnMenu={false}
      />
      <CreatePractitioner
        open={toggleDialog}
        toggleCallback={closeDialog}
      ></CreatePractitioner>

      {toggleEditDialog ? (<EditPractitioner
        data={selectedRowData.current}
        open={toggleEditDialog}
        toggleCallback={closeEditDialog}
      ></EditPractitioner>) : (<></>)}

      {toggleReassignDialog ? (
        <DeleteAndReassign
          data={selectedRowData}
          patientIds={assignedPatients.current.patientIds}
          open={toggleReassignDialog}
          toggleCallback={closeDeleteReassignDialog}
        ></DeleteAndReassign>
      ) : (
        <></>
      )}

      <DeletePractitioner
       data={selectedRowData}
        open={toggleDeleteDialog}
        toggleCallback={closeDeleteDialog}
      ></DeletePractitioner>

      <ResetPassword  data={selectedRowData} open={toggleResetDialog}
        toggleCallback={closeResetDialog}> </ResetPassword>

    </div>
    </React.Fragment>
  );
}
