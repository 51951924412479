import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PractitionersList from '../components/users/practitioners-list';
import { ApolloProvider } from '@apollo/client';
import { client } from '../core/graphql/request'
import { useEffect } from 'react';
import axios from 'axios';
import { IAppDetails } from '../core/interface/general/IGeneral';
import AppContext, { AppContextType } from '../core/context/auth-context';

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function OverviewPage() {
  const [value, setValue] = React.useState<number>(0);
  const { appContext, setAppContext } = React.useContext(AppContext) as AppContextType;

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {

    if (sessionStorage.getItem('providerId') && !appContext.selectedProviderId) {
      setAppContext({
        'selectedProviderId': sessionStorage.getItem('providerId')
      })
    }
    if (appContext.selectedProviderId) {
      axios.get(`${process.env.REACT_APP_MEDICAL_CONTENT_URL}/providers/${appContext.selectedProviderId}`, {
        headers: {
          'authorization': sessionStorage.getItem('adminToken') as string
        }
      }).then((res: any) => {
        setAppContext((prevState: IAppDetails) => ({
          ...prevState,
          ...{
            'careTeams': res.data.careTeams,
            'selectedProviderId': appContext.selectedProviderId
          }
        }))
      })
    }
  }, [appContext.selectedProviderId])// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ApolloProvider client={client}>
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs></Tabs>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Users" {...a11yProps(0)} />
            <Tab label="Departments" {...a11yProps(1)} />
            <Tab label="Accounts" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <PractitionersList></PractitionersList>
        </TabPanel>
        <TabPanel value={value} index={1}>
          Item Two
        </TabPanel>
        <TabPanel value={value} index={2}>
          Item Three
        </TabPanel>
      </Box>
    </ApolloProvider>
  );
}
