import { Box, Button, Grid, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import APP_CONST from "../../../core/constants/app-constants";

const PersonalDetailsForm = (props: any) => {
  let defaultData: any = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  };

  useEffect(() => {
    for (const [key] of Object.entries(defaultData)) {
      setValue(key, props.data[key]);
    }
    // eslint-disable-next-line
  }, []);

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: defaultData,
  });

  const onSubmit = (data: any) => {
    props.nextCallBack(data);
  };

  const changePhoneNumber = (data: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    var phoneNmbrNoSpaces = data.currentTarget.value.replace(/ /g, "");
    if(phoneNmbrNoSpaces.startsWith('06')) {
      setValue('phoneNumber', phoneNmbrNoSpaces.replace('0', '+31'));
    }
    else {
      setValue('phoneNumber', phoneNmbrNoSpaces);
    }
  }
  
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Grid container spacing={2}>
        <Grid item>
          <Box sx={{ m: 2 }}>
            <Controller
              name="firstName"
              control={control}
              rules={{
                required: "This is required",
              }}
              render={({
                field: { ref, ...field },
                fieldState: { invalid, error },
              }) => (
                <TextField
                  fullWidth
                  required
                  label="First Name"
                  {...field}
                  inputRef={ref}
                  error={invalid}
                />
              )}
            />
            <ErrorMessage className="error-message" errors={errors} name="firstName" as="p"/>
          </Box>
        </Grid>
        <Grid item>
          <Box sx={{ m: 2 }}>
            <Controller
              name="lastName"
              control={control}
              rules={{
                required: "This is required",
              }}
              render={({
                field: { ref, ...field },
                fieldState: { invalid, error },
              }) => (
                <TextField
                  fullWidth
                  required
                  label="Last Name"
                  {...field}
                  inputRef={ref}
                  error={invalid}
                />
              )}
            />
            <ErrorMessage className="error-message" errors={errors} name="lastName" as="p" />
          </Box>
        </Grid>
        <Grid item>
          <Box sx={{ m: 2 }}>
            <Controller
              name="email"
              control={control}
              rules={{
                required: "This is required",
                pattern: {
                  value: APP_CONST.REGEX.EMAIL,
                  message: "Invalid Email Address"
                }
              }}
              render={({
                field: { ref, ...field },
                fieldState: { invalid, error },
              }) => (
                <TextField
                  fullWidth
                  required
                  label="E-Mail"
                  {...field}
                  inputRef={ref}
                  error={invalid}
                />
              )}
            />
           <ErrorMessage className="error-message" errors={errors} name="email" as="p" />
          </Box>
        </Grid>
        <Grid item>
          <Box sx={{ m: 2 }}>
            <Controller
              name="phoneNumber"
              control={control}
              rules={{
                required: "This is required",
                pattern: {
                  value: APP_CONST.REGEX.PHONE_NUMBER,
                  message: "Invalid Phone number"
                }
              }}
              render={({
                field: { ref, ...field },
                fieldState: { invalid, error },
              }) => (
                <TextField
                  fullWidth
                  required
                  label="Phone Number"
                  {...field}
                  inputRef={ref}
                  error={invalid}
                  onBlur={(e) => changePhoneNumber(e)}
                />
              )}
            />
            <ErrorMessage className="error-message" errors={errors} name="phoneNumber" as="p" />
          </Box>
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end">
        <Button variant="contained" type="submit">
          Next
        </Button>
      </Grid>
    </form>
  );
};

export default PersonalDetailsForm;


