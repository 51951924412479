import React from "react";
import { IAppDetails } from "../interface/general/IGeneral";


export type AppContextType = {
    appContext: IAppDetails;
    setAppContext: (data: any) => void;
}

const AppContext =  React.createContext<AppContextType | null>(null);

export default AppContext;
