import { Box, Button, Container, Grid, TextField } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import DHBackdrop from '../generic/dh-backdrop';
import { Controller, useForm } from 'react-hook-form';
import PasswordLessAuthService from '../../core/service/passwordless-service';
import { useSnackbar } from 'notistack';
import { IAppDetails } from '../../core/interface/general/IGeneral';
import AppContext, { AppContextType } from '../../core/context/auth-context';
import dearLogo from '../../assets/images/dear_logo.png';

const SmsVerifyComponent = () => {
    const {state} = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [loader, setLoader] = useState<boolean>(false);
    const {setAppContext} = useContext(AppContext) as AppContextType;
    const passwordLessAuthService = new PasswordLessAuthService();
    const defaultData = {
        smsCode: ""
    };
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: defaultData,
    });

    const onSubmit = async (loginData: any) => {
        setLoader(true);
        try {
            const data: any = await passwordLessAuthService.confirmSignIn(loginData.smsCode, state.nextStep.signInStep);
            if(data.nextStep?.additionalInfo?.challengeType === 'SMS') {
              enqueueSnackbar('Invalid mail code', {variant: 'error'});
            } else {
              const tokens = await passwordLessAuthService.getTokens();
              sessionStorage.clear();
              sessionStorage.setItem('token', tokens.idToken!);
              setAppContext((prevState: IAppDetails) => ({
                ...prevState,
                ...{ isLoggedIn: true, authToken: tokens.idToken, refreshToken: tokens.idToken }
              }));
              navigate("/admin/providers");
              setLoader(false);
            }
        } catch (error) {
        } finally {
            setLoader(false);
        }
    };

    return (
        <React.Fragment>
          {loader && <DHBackdrop></DHBackdrop>}
    
          <Container>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              style={{ minHeight: "100vh" }}
            >
              <img src={dearLogo} alt="logo" />
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Box sx={{ m: 2 }}>
                  <Controller
                    name="smsCode"
                    control={control}
                    rules={{
                      required: "This is required",
                      minLength: { value: 6, message: "This is not a valid code" },
                    }}
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <TextField
                        fullWidth
                        required
                        label="SMS Code"
                        {...field}
                        inputRef={ref}
                        error={invalid}
                      />
                    )}
                  />
                  {errors.smsCode && (
                    <Box sx={{ color: "error.main" }}>
                      <p>{errors.smsCode?.message}</p>
                    </Box>
                  )}
                </Box>
    
                <Box sx={{ textAlign: "center" }}>
                  <Button type="submit" variant="contained">
                    Submit
                  </Button>
                </Box>
              </form>
            </Grid>
          </Container>
        </React.Fragment>
      );
}

export default SmsVerifyComponent;

