import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import { useMutation } from "@apollo/client/react";
import { gql } from '@apollo/client';
import React, { useContext, useEffect, useMemo } from "react";
import APP_CONST from "../../core/constants/app-constants";
import { useForm, Controller } from "react-hook-form";
import { ICareTeams, IDisease } from "../../core/interface/general/IGeneral";
import AppContext, { AppContextType } from "../../core/context/auth-context";
import { ErrorMessage } from "@hookform/error-message";
import DHBackdrop from "../generic/dh-backdrop";
import { withLDConsumer } from 'launchdarkly-react-client-sdk';



// eslint-disable-next-line
type UpdatePractitionerInput = {
	providerId: String
  id: String
	firstName: String
	lastName: String
	email: String
	phoneNumber: String
	diseases: [String]
	careTeams: [String]
	externalId: String
}
const UPDATE_PRACTITIONER = gql`
mutation MyMutation($input: UpdatePractitionerInput) {
	updatePractitioner(input: $input) {
    practitionerId
  }
}`;


const EditPractitioner = withLDConsumer()((props: any) => {
  const [mutateFunction, { loading }] = useMutation(UPDATE_PRACTITIONER);
  const { appContext } = useContext(AppContext) as AppContextType;

  const { data } = props;
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => data, 
     // eslint-disable-next-line 
     [data.id]),
  });

  useEffect(() => {
    for (const [key, value] of Object.entries(data)) {
        setValue(key, value)
    }
     // eslint-disable-next-line
}, [data.id])


  const onSubmit = (data: any) => {
    const diseaseIds: string[] = [];
    data.careTeams.forEach((selectedCareTeamID: string) => {
      const selectedCareTeam: ICareTeams = appContext.careTeams.filter(
        (careTeam: ICareTeams) => careTeam.id === selectedCareTeamID
      )[0];
      selectedCareTeam.diseases.forEach((disease: IDisease) => {
        diseaseIds.push(disease.id);
      });
    });
    data.diseases = diseaseIds;
    data.providerId = appContext.selectedProviderId;
    mutateFunction({
      variables: {input: data}
    }).then((data) => {
    }).finally(() => {
      props.toggleCallback(data);
    })
  };

  const changePhoneNumber = (
    data: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => {
    var phoneNmbrNoSpaces = data.currentTarget.value.replace(/ /g, "");
    if(phoneNmbrNoSpaces.startsWith('06')) {
      setValue('phoneNumber', phoneNmbrNoSpaces.replace('0', '+31'));
    }
    else {
      setValue('phoneNumber', phoneNmbrNoSpaces);
    }
  };

  return (
    <React.Fragment>
      {loading && <DHBackdrop></DHBackdrop>}
      <div>
        <Dialog open={props.open} onClose={props.toggleCallback} fullWidth>
          <DialogTitle>Edit Practitioner</DialogTitle>
          <DialogContent>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Grid container spacing={2}>
                <Grid item>
                  <Box sx={{ m: 2 }}>
                    <Controller
                      name="firstName"
                      control={control}
                      rules={{
                        required: "This is required",
                      }}
                      render={({
                        field: { ref, ...field },
                        fieldState: { invalid, error },
                      }) => (
                        <TextField
                          fullWidth
                          required
                          label="First Name"
                          {...field}
                          inputRef={ref}
                          error={invalid}
                        />
                      )}
                    />
                    <ErrorMessage className="error-message" errors={errors} name="firstName" as="p"/>
                  </Box>
                </Grid>
                <Grid item>
                  <Box sx={{ m: 2 }}>
                    <Controller
                      name="lastName"
                      control={control}
                      rules={{
                        required: "This is required",
                      }}
                      render={({
                        field: { ref, ...field },
                        fieldState: { invalid, error },
                      }) => (
                        <TextField
                          fullWidth
                          required
                          label="Last Name"
                          {...field}
                          inputRef={ref}
                          error={invalid}
                        />
                      )}
                    />
                    <ErrorMessage className="error-message" errors={errors} name="lastName" as="p" />
                  </Box>
                </Grid>
                <Grid item>
                  <Box sx={{ m: 2 }}>
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: "This is required",
                        pattern: {
                          value: APP_CONST.REGEX.EMAIL,
                          message: "Invalid Email Address",
                        },
                      }}
                      render={({
                        field: { ref, ...field },
                        fieldState: { invalid, error },
                      }) => (
                        <TextField
                          fullWidth
                          required
                          label="E-Mail"
                          {...field}
                          inputRef={ref}
                          error={invalid}
                        />
                      )}
                    />
                    <ErrorMessage className="error-message" errors={errors} name="email" as="p" />
                  </Box>
                </Grid>
                <Grid item >
                  <Box sx={{ m: 2 }}>
                    <Controller
                      name="phoneNumber"
                      control={control}
                      rules={{
                        required: "This is required",
                        pattern: {
                          value: APP_CONST.REGEX.PHONE_NUMBER,
                          message: "Invalid Phone number",
                        },
                      }}
                      render={({
                        field: { ref, ...field },
                        fieldState: { invalid, error },
                      }) => (
                        <TextField
                          fullWidth
                          required
                          label="Phone Number"
                          {...field}
                          inputRef={ref}
                          error={invalid}
                          onBlur={(e) => changePhoneNumber(e)}
                        />
                      )}
                    />
                    <ErrorMessage className="error-message" errors={errors} name="phoneNumber" as="p" />
                  </Box>
                </Grid>
                <Grid item>
          <Box sx={{ m: 2 }}>
            <Controller
              name="careTeams"
              control={control}
              rules={{
                required: "This is required",
              }}
              render={({
                field: { ref, ...field },
                fieldState: { invalid, error },
              }) => (
                <>
                  <Select
                    labelId="label"
                    fullWidth
                    multiple
                    required
                    {...field}
                    inputRef={ref}
                    error={invalid}
                    label="Care Teams"
                  >
                    <MenuItem disabled value="">
                      <em>Care Teams</em>
                    </MenuItem>
                    {appContext.careTeams.map((name: ICareTeams) => (
                      <MenuItem key={name.id} value={name.id}>
                        {name.id}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            />
            {/* {errors.careTeams && (
              <Box sx={{ color: "error.main" }}>
                <p>{errors.careTeams?.message}</p>
              </Box>
            )} */}
          </Box>
          </Grid> 
            <Grid item >
              <Box sx={{ m: 2 }}>
                <Controller
                  name="externalId"
                  control={control}
                  render={({
                    field: { ref, ...field },
                    fieldState: { invalid, error },
                  }) => (
                    <TextField
                      fullWidth
                      required
                      label="External ID"
                      {...field}
                      inputRef={ref}
                      error={invalid}
                    />
                  )}
                />
                <ErrorMessage className="error-message" errors={errors} name="externalId" as="p" />
              </Box>
            </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Button variant="contained" type="submit">
                Submit
              </Button>
            </Grid>
            </form>
          </DialogContent>
        </Dialog>
      </div>
    </React.Fragment>
  );
}
);

export default EditPractitioner;
