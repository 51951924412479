import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  DefaultOptions,
  ApolloLink
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
// const retryIf = (error: { statusCode: number }, operation: any) => {
//   const doNotRetryCodes = [400, 401];
//   return !!error && !doNotRetryCodes.includes(error.statusCode);
// };
// const retryLink = new RetryLink({
//   delay: {
//     initial: 1000,
//     max: 2000,
//     jitter: true,
//   },
//   attempts: {
//     max: 3,
//     retryIf,
//   },
// });


// Retry for one more yime if there is a graphQL error
// const errorLink = onError(
//   ({ graphQLErrors, networkError, operation, forward }) => {
//     if (graphQLErrors)
//       graphQLErrors.forEach((error) => {
//         const { errorType } = error as any;
//         switch (errorType) {
//           case "PatientsAreStillAssigned":

//             return forward(operation)
//         }
//       });
//   }
// );

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = sessionStorage.getItem("adminToken");
  return {
    headers: {
      ...headers,
      Authorization: token ? `${token}` : "",
    },
  };
});

const links = ApolloLink.from([ authLink, httpLink]);

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

export const client = new ApolloClient({
  link: links, //authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});
