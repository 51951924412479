import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    Grid,
  } from "@mui/material";
  import React, { useContext, useState } from "react";
  import { useTranslation } from "react-i18next";
  import { postData } from "../../core/api/rest-api";
  import DHBackdrop from "../generic/dh-backdrop";
  import AppContext, { AppContextType } from "../../core/context/auth-context";
  import { useSnackbar } from "notistack";
  
  const ResetPassword = (props: any) => {
    const [loader, setLoader] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();
    const { appContext } = useContext(AppContext) as AppContextType;
    const { t } = useTranslation();
    const resetpractitionerPassword = async () => {
      setLoader(true);
      try {
        const payload =  {
         providerName: appContext.selectedProviderId,
         username: props.data.current.id,
       };
        await postData("/api/auth/practitioner-force-new-password", JSON.stringify(payload));
        setLoader(false);
        enqueueSnackbar(t("reset_practitioner.reset_password_success"), {variant: "success"})
      } catch(e) {
        setLoader(false);
        enqueueSnackbar(t("error_message"), { variant: "error" });
      } finally {
        props.toggleCallback();
      }
    };
  
    return (
      <React.Fragment>
        {loader && <DHBackdrop></DHBackdrop>}
        <div>
          <Dialog open={props.open} onClose={props.toggleCallback} fullWidth>
            <DialogTitle>Reset password</DialogTitle>
            <DialogContent>
              <p>{t("reset_practitioner.confirmation_text")}</p>
              <Grid container justifyContent="flex-end" sx={{ my: 2 }}>
                <Button
                  variant="contained"
                  type="submit"
                  onClick={resetpractitionerPassword}
                >
                  Submit
                </Button>
              </Grid>
            </DialogContent>
          </Dialog>
        </div>
      </React.Fragment>
    );
  };
  
  export default ResetPassword;
  