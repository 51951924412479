/* eslint-disable no-useless-escape */
const APP_CONST = {
    REGEX: {
        EMAIL: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        PHONE_NUMBER: /([+]1)\d{10}|([+]316)\d{8}$/,
    },
    MOMENT_DATE_TIME_FORMAT: 'DD-MM-yyyy HH:mm',
    RETRY_Count: 3
}

export default APP_CONST;