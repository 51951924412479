import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "Welcome to React": "Welcome to React and react-i18next",
      "error_message": "Something went wrong",
      "overview_page": {
        "no_last_login_text": "No last login",
        "month_text": "Month",
        "months_text": "Months",
      },
      "delete_practitioner": {
        "info_text_1": "You are deleting a Practitioner",
        "info_text_2": "Before doing this action, please select another practitioner that will receive the patients from the practitioners you are deleting.",
        "select_practitioner_label": "Select Practitioner", 
        "confirmation_text": "Are you sure to Delete this practitioner ?",
        "delete_success": "Practitioner deleted successfully"
      }, 
      "reset_practitioner": {
        "reset_password_success": "Password resetted successfully", 
        "confirmation_text": "You are reseting this user’s password. A new password will be sent to the practitioner’s email."
      }
    }
  },
  fr: {
    translation: {
      "Welcome to React": "Welcome to React and react-i18next",
      "error_message": "Something went wrong",
      "overview_page": {
        "no_last_login_text": "No last login",
        "month_text": "Month",
        "months_text": "Months",
      },
      "delete_practitioner": {
        "info_text_1": "You are deleting a Practitioner",
        "info_text_2": "Before doing this action, please select another practitioner that will receive the patients from the practitioners you are deleting.",
        "select_practitioner_label": "Select Practitioner", 
        "confirmation_text": "Are you sure to Delete this practitioner ?",
        "delete_success": "Practitioner deleted successfully"
      }, 
      "reset_practitioner": {
        "reset_password_success": "Password resetted successfully", 
        "confirmation_text": "You are reseting this user’s password. A new password will be sent to the practitioner’s email."
      }
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;