import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
} from "@mui/material";
import React, { useContext } from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { DELETE_PRACTITIONER } from "../../../core/graphql/queries";
import DHBackdrop from "../../generic/dh-backdrop";
import AppContext, { AppContextType } from "../../../core/context/auth-context";
import { useSnackbar } from "notistack";

const DeletePractitioner = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const [mutateFunction, { loading }] = useMutation(DELETE_PRACTITIONER);
  const { appContext } = useContext(AppContext) as AppContextType;
  const { t } = useTranslation();
  const deletePractitioner = () => {
    mutateFunction({
      variables: {
        input: {
          id: props.data.current.id,
          providerId: appContext.selectedProviderId,
        },
      },
    })
      .then((data) => {
        enqueueSnackbar(t("delete_practitioner.delete_success"), {
          variant: "success"
        });
      })
      .finally(() => {
        props.toggleCallback();
      });
  };

  return (
    <React.Fragment>
      {loading && <DHBackdrop></DHBackdrop>}
      <div>
        <Dialog open={props.open} onClose={props.toggleCallback} fullWidth>
          <DialogTitle>Delete Practitioner</DialogTitle>
          <DialogContent>
            <p>{t("delete_practitioner.confirmation_text")}</p>
            <Grid container justifyContent="flex-end" sx={{ my: 2 }}>
              <Button
                variant="contained"
                type="submit"
                onClick={deletePractitioner}
              >
                Submit
              </Button>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    </React.Fragment>
  );
};

export default DeletePractitioner;
