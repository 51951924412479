import { useMutation } from "@apollo/client/react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Step,
  StepButton,
  Stepper,
} from "@mui/material";
import React, { useState } from "react";
import { CREATE_PRACTITIONER } from "../../../core/graphql/queries";
import AccountDetailsForm from "./account-details-form";
import PersonalDetailsForm from "./personal-details-form";
import DHBackdrop from "../../generic/dh-backdrop";

function getSteps() {
  return ["Personal Details", "Account Details"];
}


const CreatePractitioner = (props: any) => {
  const steps = getSteps();
  const [activeStep, setActiveStep] = useState(0);
  const [practitionerpayload, setpractitionerpayload] = useState({});
  const [mutateFunction, { loading }] = useMutation(CREATE_PRACTITIONER);

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
         <PersonalDetailsForm nextCallBack={handleNext} data={practitionerpayload}></PersonalDetailsForm>
        );
      case 1:
        return (
         <AccountDetailsForm backCallBack={handleBack} submitCallBack={submitHandler} data={practitionerpayload}></AccountDetailsForm>
        );
    }
  }

  const submitHandler = (data: any) => {
   const payload = {...practitionerpayload, ...data};
   mutateFunction({
     variables: {input: payload}
   }).then((data) => {
   }).finally(() => {
    handleDialogClose();
   })
  }

  const handleDialogClose = () => {
    setpractitionerpayload({});
    props.toggleCallback();
  };

  const handleNext = (data: any) => {
    setpractitionerpayload((prevData: any) =>  ({...prevData, ...data})) ;
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = (data: any) => {
    setpractitionerpayload((prevData: any) =>  ({...prevData, ...data})) ;
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <React.Fragment>
    {loading && <DHBackdrop></DHBackdrop>}
    <div>
      <Dialog open={props.open} onClose={handleDialogClose} fullWidth>
        <DialogTitle>Create Practitioner</DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%" }}>
            <Stepper nonLinear activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepButton >
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            <div>
              {<React.Fragment>{getStepContent(activeStep)}</React.Fragment>}
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
    </React.Fragment>
  );
};

export default CreatePractitioner;
