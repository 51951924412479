import React, { useContext, useEffect, useState } from "react";
import { getData } from "../core/api/rest-api";
import axios from 'axios';
import Grid from "@mui/material/Grid";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActionArea,
} from "@mui/material";
import { IProviders } from "../core/interface/general/IGeneral";
import { useNavigate } from "react-router-dom";
import AppContext, { AppContextType } from "../core/context/auth-context";

const Providers = () => {
  const [providerList, setProviderLst] = useState<IProviders[]>();
  const {setAppContext } = useContext(AppContext) as AppContextType;
  const navigate = useNavigate();
  useEffect(() => {
    async function fetchProvider() {
      try {
        const data = await (await getData("/providers")).data;
        setProviderLst(data);
      } catch (e) {
        console.log(e);
      }
    }
    fetchProvider();
  }, []);

  const getRegionfromUrl = () => {
    return window.location.host.split('.').pop();
  }

  const navigateToOverview = async (providerId: string) => {
    let response: any;
    if(getRegionfromUrl() === 'com') {
      response = await axios.get(`${process.env.REACT_APP_practitionerAuthTokenApi_US}${providerId}&role=admin`, {
        headers: {
          'Authorization':  sessionStorage.getItem('token')!
        }
      })
    } else {
      response = await axios.get(`${process.env.REACT_APP_practitionerAuthTokenApi_EU}${providerId}&role=admin`, {
        headers: {
          'Authorization':  sessionStorage.getItem('token')!
        }
      })
    }
    sessionStorage.setItem('adminToken', response.data['pracAuthToken']);
    sessionStorage.setItem('providerId', providerId);
    setAppContext({
      'selectedProviderId': providerId,
      'authToken': response.data['pracAuthToken'],
    })
    navigate('/admin/overview');
  }
  return (
    <React.Fragment>
      <h1> Providers</h1>
      <Grid container spacing={3}>
      {providerList?.map((item: IProviders) => (
        <Grid item xs={4} key={item.id}>
          <CardActionArea onClick= {() => navigateToOverview(item.id)}>
          <Card >
            <CardMedia style={{objectFit: "contain"}}
              component="img"
              alt={item.name}
              width = "100%"
              height="100"
              image={item.logoUrl}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
              {item.name}
              </Typography>
            </CardContent>
          </Card>
          </CardActionArea>
        </Grid>
      ))}
      </Grid>
    </React.Fragment>
  );
};

export default Providers;
